import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/seo";

// eslint-disable-next-line
export const ProductPageTemplate = ({
    title,
    heading,
    description,
    description2,
    description3,
    testimonials,
 }) => {
//     const pimage = getImage(preiseImage) || preiseImage;
//     const alt = `gastrokalk Programm Preise`;

    return (
        <div className="content">
            <div>
                <div className="container">
                    <div>
                        <p>{title}</p>
                        {/* <div className="columns">
                            <div className="column is-10 is-offset-1">
                                <h1 className="has-text-weight-semibold is-size-2 hero-header border-start border-black ps-2 py-2">
                                    {heading}
                                </h1>
                                <p className="has-text-centered">{description}</p>
                                <p className="has-text-centered "><a href="#programme7">{description2}</a></p>
                                <p className="has-text-centered">{description3}</p>
                                <p className="has-text-centered">Wer benutzt gastrokalk? <a href="#referenzen">Siehe Referenzen</a></p>
                            </div>
                        </div> */}
                        <div className="columns">
                            <div className="column is-10 is-offset-1">
                                {/* <Features gridItems={intro.blurbs} />
                                <FullWidthImage img={pimage} alt={alt} />
                                <div className="notification is-warning" style={{ border: "7px solid #bfdaf0" }}>
                                    <h2 className="column svc-header" style={{ display: "flex", justifyContent: "center" }}>{services.heading}</h2>
                                    <div className="column">
                                        {services.items.map((item) => (
                                            <div key={item} className="is-size-5">
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                </div> */}
                                {/* <div className="has-text-centered" id="referenzen">
                                    <h2>{main.heading}</h2>
                                </div> */}
                                {/* <Testimonials testimonials={testimonials} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

 ProductPageTemplate.propTypes = {
     title: PropTypes.string,
//     heading: PropTypes.string,
//     description: PropTypes.string,
//     description2: PropTypes.string,
//     description3: PropTypes.string,
//     preiseImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//     intro: PropTypes.shape({
//         blurbs: PropTypes.array,
//     }),
//     services: PropTypes.shape({
//         heading: PropTypes.string,
//         items: PropTypes.array,
//     }),
//     main: PropTypes.array,
//     testimonials: PropTypes.array,
 };

const ProductPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
        const location = "archive product"

    return (
        <Layout location={location}>
            <ProductPageTemplate
                title={frontmatter.title}
                // heading={frontmatter.heading}
                // description={frontmatter.description}
                // description2={frontmatter.description2}
                // description3={frontmatter.description3}
                // preiseImage={frontmatter.preiseImage}
                // intro={frontmatter.intro}
                // services={frontmatter.services}
                // main={frontmatter.main}
                // testimonials={frontmatter.testimonials}
            />
        </Layout>
    );
};

ProductPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
};

export default ProductPage;

export const Head = () => <Seo title="Products" />

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
  }
`;
